body {
  margin: 0;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  font-size: 14px;
  box-sizing: border-box;
  margin: 0;
  font-family: 'Poppins', serif;
}

@media (max-width: 500px) {
  * {
    font-size: 0.85rem;
  }
}

.ReactModal__Overlay--after-open {
  background-color: rgb(0 0 0 / 65%) !important;
  transition: background-color 0.3s ease-in-out;

  @media (max-width: 500px) {
    padding: 2rem 0.5rem !important;
  }
}

:root {
  --custom-btn-txt: #1b203d;
  --custom-btn-bg: #263d82;
  --custom-btn-bg-hover: #102e66;
  --custom-primary-bg: #fff;
  --custom-secondary-bg: #263d82;
  --custom-border-color: #d1d1d1;
  --custom-active-bg: #27a1d9;
  --custom-input-bg: #f2f2f2;
  --custom-cream-color: #c0fdd4;
  --custom-input-border: #e0dfdf;
  --custom-table-border: #d1d1d1;
  --custom-muted-text-color: #6c757d;
  --custom-white: #fff;
  --custom-txt-white: #ffffff81;
  --custom-black: #1b203d;
  --custom-txt-logo: #1ab7fd;
  --custom-topbar-bg: #2a3f5a;
  --custom-home-btn-bg1: #cbcbcb;
  --custom-home-btn-bg2: #91c869;
  --custom-home-btn-bg3: #d5bb16;

  --custom-footer-txt: #d3dcec;
  --custom-background-grey: rgba(230, 230, 230, 1);
  --custom-light-line_break-color: rgba(245, 245, 245, 1);
  --custom-deep-line_break-color: rgba(140, 140, 140, 1);
  --custom-blue-color: #0275d8;
  --custom-green-color: #4b9339;
  --custom-badge-success: #13923d;
  --custom-badge-danger: red;
  --custom-badge-info: #4b9339;
  --custom-badge-custom: #1c70c8;

  --color-change-transition: 200ms ease-in-out color;
  /* duetics code */

  /* fonts sizes */

  /* font family */
  --logo-font: 'RushingNightShade', sans-serif;
  --font-1: 'Quicksand', sans-serif;
  --font-2: 'Merriweather', serif;

  --toastify-toast-width: auto !important;
}

body {
  background-color: var(--custom-primary-bg);
}

input[type='radio' i]:focus-visible {
  outline-offset: 0px;
  border: none;
  outline: none;
}

.ADcontainer {
  max-width: 80%;
}

.badge {
  position: absolute;
  top: 2px;
  right: 10px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--custom-button-background);
  margin-top: -0.5rem;
}

/* ========================== search bar and select option styling */
.searchActions {
  display: flex;
  margin-bottom: 2rem;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .searchActions {
    flex-wrap: wrap;
  }
}

.searchActions .helperText {
  flex: auto;
  font-weight: 500;
}

.searchActions .selectwrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}

.searchActions .actionButtonWrapper button {
  background-color: transparent;
  border: 1px solid var(--custom-btn-bg);
  color: var(--custom-btn-bg);
}

.searchActions .searchOuter {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  border: 1px solid #ddd;
  border-radius: 2.5rem;
  background-color: #fff;
  height: 3rem;
  width: 100%;
  max-width: 33.9375rem;
  align-items: center;
  padding: 0 1rem;
}

.searchActions .searchOuter input {
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
}

.searchActions .searchOuter .searchIcon {
  cursor: pointer;
  width: 20px;
}

.searchActions .selectOuter select {
  appearance: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  min-width: 140px;
  max-width: 170px;
  width: 100%;
  padding: 0.6rem;
  text-indent: 10px;
  background: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    no-repeat !important;
  background-color: var(--custom-btn-bg) !important;
  border-radius: 25px;
  outline: none;
  background-image: none;
  overflow: hidden;
  color: var(--custom-white);
  z-index: 0;
  background-position-x: 90% !important;
  background-position-y: 50% !important;
}
.inputOuter select {
  appearance: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    no-repeat !important;
  background-color: var(--custom-input-bg) !important;
  padding-left: 1rem;
  background-position-x: 95% !important;
  background-position-y: 50% !important;
}
.searchActions .selectOuter select option {
  border-radius: 25px;
  width: 32%;
  background-color: #fff;
  color: #000;
}

/* ========================== search bar and select option styling  end */
.subItem {
  position: relative;
}

@media (max-width: 1280px) {
  .ADcontainer {
    max-width: 90%;
  }
}

@media (max-width: 1024px) {
  .ADcontainer {
    max-width: 95%;
  }
}

*:not(i) {
  font-family: 'Poppins', sans-serif;
}

root {
  font-size: 16px;
}

::-webkit-scrollbar {
  background: var(--custom-input-border);
  height: 4px;
  width: 4px;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--custom-input-border);
  height: 4px;
  width: 4px;
  margin: 0;
  padding: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--custom-btn-bg);
  height: 4px;
  width: 4px;
  margin: 0;
  padding: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--custom-btn-bg);
  height: 4px;
  width: 4px;
  margin: 0;
  padding: 0;
}

/* multi form */
.multi-step {
  height: 100%;
  width: 100%;
}

ol.progtrckr {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin-bottom: 5rem;
}

ol.progtrckr li:first-child {
  max-width: 6.5rem;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  position: relative;
  line-height: 2.5rem;
  width: 100%;
  /* max-width: 13rem; */
  cursor: pointer;
}

ol.progtrckr li span {
  position: absolute;
  top: 6rem;
  right: -2rem;
  line-height: 1.5rem;
  margin-left: 0rem;
  width: 7rem;
}

@media (max-width: 1000px) {
  .progtrckr li span {
    font-size: 0.85rem;
  }
}

@media (max-width: 880px) {
  ol.progtrckr li span {
    right: -1rem;
    line-height: 1rem;
    width: 5rem;
    font-size: 0.7rem;
  }
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}

ol.progtrckr li.progtrckr-todo em {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 48%;
  color: #8e8e8e;
  background-color: silver;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

ol.progtrckr li.progtrckr-doing em {
  background-color: #1c4489;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 48%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }

  ol.progtrckr li.progtrckr-todo em,
  ol.progtrckr li.progtrckr-doing em,
  ol.progtrckr li.progtrckr-doing:before,
  ol.progtrckr li.progtrckr-done:before,
  ol.progtrckr li.progtrckr-todo:before {
    width: 40px !important;
    height: 40px !important;
  }

  ol.progtrckr li.progtrckr-doing:before,
  ol.progtrckr li.progtrckr-done:before,
  ol.progtrckr li.progtrckr-todo:before {
    top: 48%;
  }
}

@media (max-width: 348px) {
  ol.progtrckr li.progtrckr-todo em,
  ol.progtrckr li.progtrckr-doing em,
  ol.progtrckr li.progtrckr-doing:before,
  ol.progtrckr li.progtrckr-done:before,
  ol.progtrckr li.progtrckr-todo:before {
    top: 50%;
  }
}

ol.progtrckr li.progtrckr-done em {
  display: none;
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 3px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: #1c4489;
  border-bottom: 3px solid #1c4489;
}

ol.progtrckr li.progtrckr-done {
  color: #91c869;
  border-bottom: 3px solid #1c4489;
}

ol.progtrckr li:after {
  content: '\00a0';
}

ol.progtrckr li:before {
  position: relative;
  bottom: -1.8rem;
  float: right;
  right: -0%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: '';
  opacity: 0;
  color: silver;

  width: 55px;
  height: 55px;
  border-radius: 50%;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: '';

  background-color: #1c4489;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: '\2713';
  color: white;
  background-color: #91c869;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  border-radius: 50%;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.spinnerWrapper {
  width: 100%;
  justify-self: center;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

td {
  text-align: center !important;
}
